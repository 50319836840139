import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/diarree-bij-kinderen/"> Diarree bij kinderen</a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <p>
                  Rond het behandelen van de symptomen van diarree bestaan er
                  nog vele misverstanden en&nbsp;mythes. Hier vind je enkele
                  tips over het behandelen van de symptomen van diarree.
                </p>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Hoe werkt IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Hoe werkt IMODIUM®?"
                  />
                </div>
                <p>
                  Volgend filmpje toont je de werking van{" "}
                  <strong>
                    IMODIUM<sup>®</sup>
                  </strong>
                  : hoe <strong>diarree</strong> belangrijke vloeistoffen en
                  mineralen aan je lichaam onttrekt en hoe{" "}
                  <strong>
                    IMODIUM<sup>®</sup>{" "}
                  </strong>
                  het natuurlijke evenwicht in je darm herstelt.
                </p>
                <a href="/hoe-werkt-imodium-instant/">Meer informatie</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-9">
            <h1>Diarree bij kinderen</h1>
            <div className="img">
              <img
                src="/assets/files/pages/kinder.jpeg"
                width="701"
                height="289"
                alt="Diarree bij kinderen"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Wat veroorzaakt diarree bij kinderen?</h2>
              </div>
              <div className="nine columns">
                <p>
                  In de eerste drie jaar komt het gemiddeld één tot tweemaal per
                  jaar voor dat een kind diarree heeft. De hoofdoorzaak zijn
                  virussen. Tegen de bijzonder vaak optredende rotavirussen kan
                  je je kind tegenwoordig laten inenten.
                  <br />
                  Ook voedselintolerantie of -allergie kan{" "}
                  <strong>diarree bij kinderen</strong> veroorzaken. Wanneer
                  diarree geregeld optreedt, raadpleeg dan zeker een arts.
                </p>
              </div>
            </div>
            <p>
              Ongeacht de{" "}
              <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                oorzaak van de diarree
              </a>{" "}
              is het bij kinderen bijzonder belangrijk deze snel aan te pakken.
              Zeker bij baby’s en jonge kinderen.{" "}
              <strong>Wanneer kinderen diarree hebben</strong>, leidt dit nog
              sneller dan bij een volwassene tot uitdroging. Dit gaat meestal
              gepaard met een verlies aan belangrijke mineralen.
            </p>
            <h3>
              Hoe behandel ik de symptomen van diarree bij kinderen vanaf 8
              jaar?
            </h3>
            <p>
              Wanneer kinderen diarree hebben, is het in de eerste plaats
              belangrijk om ervoor te zorgen dat er voldoende vocht wordt
              ingenomen. Voor de verdere behandeling van de symptomen van
              diarree bij kinderen vanaf 8 jaar kan je{" "}
              <a href="/imodium-behandelen-diarree/">IMODIUM®</a> zonder
              voorschrift verkrijgen in je&nbsp; apotheek, drogisterij en
              supermarkt. IMODIUM® kalmeert de darm, zonder de normale functie
              ervan te beïnvloeden. Het verkort de duur van de diarree en
              beschermt daardoor tegen overmatig verlies van water en mineralen.
            </p>
            <h3>Hoe behandel ik diarree bij kinderen onder 8 jaar?</h3>
            <p>
              Voor kinderen jonger dan 8 jaar raadpleeg je best je arts in geval
              van diarree.
            </p>
            <h3>Wanneer moet ik een arts raadplegen?</h3>
            <p>
              Observeer je kind nauwlettend. Bij elke verandering in het gedrag
              van je kind moet je onmiddellijk een arts raadplegen.
            </p>
            <p>Volgende symptomen kunnen wijzen op uitdroging:</p>
            <ul>
              <li>
                Weinig en donker gekleurde urine (controleer de luier bij
                baby’s)
              </li>
              <li>Huilen zonder tranen</li>
              <li>
                Verrimpelde huid die niet meer automatisch in zijn plooi valt
                bij het optrekken ervan.
              </li>
              <li>Ingevallen ogen</li>
              <li>Sufheid</li>
              <li>Droge mond en veel drinken</li>
              <li>
                Ingevallen fontanellen bij pasgeborenen (plaats waar de schedel
                nog niet is dicht gegroeid).
              </li>
            </ul>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
